import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Container } from 'react-bootstrap'
import styles from './Legal.module.scss'

const Legal = ({ data }) => {
  return (
    <Container className={styles.container}>
      <h1>{data.title}</h1>

      {renderRichText(data.richText)}
    </Container>
  )
}

export default Legal
