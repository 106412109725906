import React from 'react'
import { graphql } from 'gatsby'
import SEO from '~/components/00-global/util/seo'
import Legal from '~/components/07-legal/Legal'
import { useIntl } from 'gatsby-plugin-intl'

const LegalPage = ({ data, pageContext }) => {
  const { locale } = useIntl()

  const localizedData = data.allContentfulLegalPage.nodes.filter(
    node => node.node_locale === locale
  )[0]

  return (
    <>
      <SEO
        title={localizedData.title}
        robots='noindex'
        pathname={`/${pageContext.slug}`}
      />
      <Legal data={localizedData} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulLegalPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        node_locale
        richText {
          raw
        }
      }
    }
  }
`

export default LegalPage
